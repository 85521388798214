export const API = {
	development: "https://api.assuarance.mayicard.com",
	test: "https://test.api.mayicard.com",
	production: "https://api.mayicard.com",
};

export const AppSettings = {
	COOKIE_TIMEOUT_IN_MINUTES: 30,
	APP_NAME: "Mayicard",
};

export const setPageTitle = (title?: string) =>
	title ? `${title} | ${AppSettings.APP_NAME}` : AppSettings.APP_NAME;
